<!-- karty s zenskyma na land page -->
<template>
  <iq-card body-class="p-0" class="iq-cards-size">
    <template v-slot:body>
      <div class="d-flex flex-wrap">
        <div class="">
          <a href="javascript:void(0)" @click="openProfile">
            <b-img fluid class="img-size" :src="$mediaBaseUrl + post.photo.url" alt=""/>
          </a>
          
          <br>
          <b-row>
            <b-col sm="6" class="flex-left">
              <h6 class="card_name">{{ post.first_name }}</h6>
            </b-col>
            <!--
            <b-col sm="6">
              <a href=""><img class="images-card-body-phone" src="../../../../assets/images/page-img/phone.png" alt=""></a>
              <a href=""><img class="images-card-body-message" src="../../../../assets/images/page-img/message.png" alt=""></a>
            </b-col>
              -->
          </b-row>
        
        </div>
        
        <!-- <div class="iq-card-header-toolbar d-flex align-items-center">
          <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
            <template v-slot:button-content>
              <b-link href="javascript:void(0)" class="text-secondary"><i class="ml-3 ri-more-2-line"></i></b-link>
            </template>
            <a class="dropdown-item p-3" href="javascript:void(0)" @click="isFollowed(!post.is_follow)">
              <div class="d-flex align-items-top">
                <div class="icon font-size-20"><i class="ri-user-unfollow-line"></i></div>
                <div class="data ml-2">
                  <span v-if="post.is_follow">
                    <h6>  Unfollow User</h6>
                  </span>
                  <span v-else>
                    <h6>  Follow User</h6>
                  </span>
                  <p class="mb-0">Stop seeing posts but stay friends.</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)" class="dropdown-item p-3">
              <div class="d-flex align-items-top">
                <div class="icon font-size-20"><i class="ri-save-line"></i></div>
                <div class="data ml-2">
                  <h6>Save Post</h6>
                  <p class="mb-0">Add this to your saved items</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)" class="dropdown-item p-3">
              <div class="d-flex align-items-top">
                <div class="icon font-size-20"><i class="ri-close-circle-line"></i></div>
                <div class="data ml-2">
                  <h6>Hide Post</h6>
                  <p class="mb-0">See fewer posts like this.</p>
                </div>
              </div>
            </a>
            <a class="dropdown-item p-3" href="javascript:void(0)">
              <div class="d-flex align-items-top">
                <div class="icon font-size-20"><i class="ri-notification-line"></i></div>
                <div class="data ml-2">
                  <h6>Notifications</h6>
                  <p class="mb-0">Turn on notifications for this post</p>
                </div>
              </div>
            </a>
          </b-dropdown>
        </div> -->
      </div>
    </template>
  </iq-card>
</template>
<script>
export default {
  name: 'SocialPost',
  props: [
    'post'
  ],
  data() {
    return {
      commentMessage: ''
    }
  },
  methods: {
    openProfile() {
      this.$router.push({path: `/profile/${this.post.id}`});
    },
    isLiked(postLike) {
      this.post.is_liked = postLike

      if (postLike) {
        this.post.likes += 1
      } else {
        this.post.likes -= 1
      }
    },
    isFollowed(follow) {
      this.post.is_follow = follow
    },
    isLikedComment(index, like) {
      this.post.comments[index].is_commentLike = like
    },
    saveComment(postComment) {
      this.post.comments.push({
        image: require('../../../../assets/images/user/user-04.jpg'),
        user: {name: 'Sandy S', msg: postComment, time: new Date()},
        is_commentLike: false
      })

      this.commentMessage = ''
    }
  }
}
</script>

<style>
.dropdown-item {
  color: #212529 !important;
}

.dropdown-menu .dropdown-item:focus, .dropdown-menu .dropdown-item:hover {
  background: transparent;
  color: var(--iq-primary) !important;
}

.col-sm-12 {
  margin: 0;
  padding: 0;
}

.card-body .iq-card-body .p-1 {
  padding: 0px;
}

.iq-cards-size {
  width: 100%;
  height: 430px;
}

.img-size {
  width: 375px;
  height: 375px;
  border-radius: 5px;
}

[dir=ltr][mode=dark] .iq-cards-size {
  background-color: white;
}

.images-card-body-phone {
  display: inline;
  margin-top: 15px;
  margin-right: 10px;
}

.images-card-body-message {
  display: inline;
  margin-top: 15px;
}

[dir=ltr][mode=dark] .card_name {
  color: black;
  opacity: 0.8;
  font-weight: bold;
  font-size: 16px;
  margin-left: 16px;
  margin-top: 18px;
}
</style>
