<template>
  <b-row>
      <b-row>
        <b-col sm="12">
            <div>
              <a v-b-toggle.collapse-1><img src="/img/search-icon.svg" class="search_filter"></a>
              <b-collapse id="collapse-1">
                <b-card class="filtration_area">
                  <h4 class="filtration_h4">{{ $t("social_app.search_girl") }}</h4>
                  <br>
                  <br>
                  <hr class="hr_filtration">
                  <div class="filtration_block" >
                    <h6 class="filtration_h6">{{ $t("social_app.name") }}</h6>
                    <b-form-input type="text" :placeholder=" $t('social_app.placeholder_name') " class="filtration_part" v-model="name"></b-form-input>
                  </div>
                  
                  <div class="filtration_block" >
                    <h6 class="filtration_h6">{{ $t("social_app.breast_size") }}</h6>
                    <b-form-input type="number" placeholder="min"  class="filtration_part" v-model="min_breast_size"></b-form-input>
                    <b-form-input type="number" placeholder="max"  class="filtration_part" v-model="max_breast_size"></b-form-input>
                  </div>
                  <div class="filtration_block" >
                    <h6 class="filtration_h6">{{ $t("social_app.age") }}</h6>
                    <b-form-input type="number" placeholder="min" class="filtration_part" v-model="min_age"></b-form-input>
                    <b-form-input type="number" placeholder="max" class="filtration_part" v-model="max_age"></b-form-input>
                  </div>
                  <!--
                  <div class="filtration_block" >
                    <h6 class="filtration_h6">Výška:</h6>
                    <b-form-input type="number" placeholder="min" class="filtration_part"></b-form-input>
                    <b-form-input type="number" placeholder="max" class="filtration_part"></b-form-input>
                  </div>
                  <div class="filtration_block" >
                    <h6 class="filtration_h6">Váha:</h6>
                    <b-form-input type="number" placeholder="min" class="filtration_part"></b-form-input>
                    <b-form-input type="number" placeholder="max" class="filtration_part"></b-form-input>
                  </div>
                  <div class="filtration_block" >
                    <h6 class="filtration_h6">Město:</h6>
                    <b-form-input type="text" placeholder="Město" class="filtration_part" v-model="city"></b-form-input>
                  </div>
                  
                  <div class="filtration_block" >
                    <h6 >Sluzby:</h6>
                      <div v-for="(item) in this.genericData.services" :key="item.id"  >
                        <input type="checkbox" :id="item.id" :value="item.id">
                        <label :for="item.id"> {{item.name}} </label>
                      </div>
                  </div>
                  --><div class="filtration_block" >
                    <h6 class="filtration_h6">{{ $t("social_app.orientation") }}</h6>
                      <div v-for="(item) in this.genericData.orientations" :key="item.id" >
                        <input type="checkbox" :value="item.id" v-model="checkedOrientations">
                        <label class="label_filtration" :for="item.id"> {{item.name}} </label>
                      </div>
                  </div> <div class="filtration_block" >
                    <h6 class="filtration_h6">{{ $t("social_app.services") }}</h6>
                      <div v-for="(item) in this.genericData.services" :key="item.id" >
                        <input type="checkbox" :value="item.id" v-model="checkedServices">
                        <label class="label_filtration" :for="item.id"> {{item.name}} </label>
                      </div>
                  </div> 
                  <button class="filtration_part filtration_submit" v-on:click="searchButton()" v-b-toggle.collapse-1>{{ $t("social_app.search") }}</button>  
                </b-card>
              </b-collapse>
            </div>
            <br>
        </b-col>    
    </b-row>
    <b-col sm="12">
      <b-row class=" m-0 p-0">
        <b-col lg="12">
          <b-row>
              <tab-content id="pills-tabContent-2">
                <tab-content-item :active="true" id="profile-feed" aria-labelled-by="pills-feed-tab" class="d-flex flex-wrap card-gap">
                  <div v-for="post in clients" :key="post.id">
                    <social-post :post="post"></social-post>
                  </div>
                </tab-content-item>
                <tab-content-item :active="false" id="profile-activity" aria-labelled-by="pills-activity-tab">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Activity timeline</h4>
                    </template>
                    <template v-slot:headerAction>
                      <b-dropdown id="dropdownMenuButton5" right variant="none">
                        <template v-slot:button-content>
                          <span class="text-primary">View All</span>
                        </template>
                        <b-dropdown-item href="#"><i class="ri-eye-fill mr-2"></i>View</b-dropdown-item>
                        <b-dropdown-item href="#"><i class="ri-close-circle-line mr-2"></i>Delete</b-dropdown-item>
                        <b-dropdown-item href="#"><i class="ri-pencil-fill mr-2"></i>Edit</b-dropdown-item>
                        <b-dropdown-item href="#"><i class="ri-printer-fill mr-2"></i>Print</b-dropdown-item>
                        <b-dropdown-item href="#"><i class="ri-file-download-fill mr-2"></i>Download</b-dropdown-item>
                      </b-dropdown>
                    </template>
                    <template v-slot:body>
                      <TimeLine :items="timelineItems"/>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="profile-friends" aria-labelled-by="pills-friend-tab">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Friends</h4>
                    </template>
                    <template v-slot:body>
                      <ul class="suggestions-lists m-0 p-0">
                        <li v-for="(item,index) in friends" :key="index" class="d-flex mb-4 align-items-center">
                          <div class="user-img img-fluid">
                            <b-img :src="item.image" alt="story-img" rounded="circle" class="avatar-40"/>
                          </div>
                          <div class="media-support-info ml-3">
                            <h6>{{ item.name }}</h6>
                            <p class="mb-0">{{ item.role }}</p>
                          </div>
                          <div class="iq-card-header-toolbar d-flex align-items-center">
                            <b-dropdown id="dropdownMenuButton40" right variant="none" menu-class="p-0">
                              <template v-slot:button-content>
                                <i class="ri-more-2-line"></i>
                              </template>
                              <b-dropdown-item href="#"><i class="ri-user-unfollow-line mr-2"></i>Unfollow</b-dropdown-item>
                              <b-dropdown-item href="#"><i class="ri-share-forward-line mr-2"></i>Share</b-dropdown-item>
                              <b-dropdown-item href="#"><i class="ri-file-copy-line mr-2"></i>Copy Link</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </li>
                      </ul>
                      <b-button href="javascript:void(0);" variant="primary" class="d-block"><i class="ri-add-line"></i>
                        Load More
                      </b-button>
                    </template>
                  </iq-card>
                </tab-content-item>
                <tab-content-item :active="false" id="profile-profile" aria-labelled-by="pills-profile-tab">
                  <iq-card>
                    <template v-slot:headerTitle>
                      <h4 class="card-title">Profile</h4>
                    </template>
                    <template v-slot:body>
                      <div class="user-detail text-center">
                        <div class="user-profile">
                          <b-img :src="require('../../../assets/images/user/user-11.png')" alt="profile-img" fluid
                                class="avatar-130"/>
                        </div>
                        <div class="profile-detail mt-3">
                          <h3 class="d-inline-block">Nik Jone</h3>
                          <p class="d-inline-block pl-3"> - Web designer</p>
                          <p class="mb-0">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
                            Ipsum has been the industry's standard dummy text ever since the 500s</p>
                        </div>
                      </div>
                    </template>
                  </iq-card>
                  
                </tab-content-item>
              </tab-content>
          </b-row>    
        </b-col>
        <!--
          <b-col lg="4">
          <iq-card>
            <template v-slot:headerTitle>
              <h4 class="card-title">{{ $t("timeline.map") }}</h4>
            </template>
            <template v-slot:body>
              <Map :fullView="false"
              />
            </template>
          </iq-card>

        </b-col>
        -->
      </b-row>
    </b-col>
    <!-- <div class="col-sm-12 text-center">
      <img src="../../../assets/images/page-img/page-load-loader.gif" alt="loader" style="height: 100px;">
    </div> -->
</b-row>
</template>
<script>
import {socialvue} from '../../../config/pluginInit'
import SocialPost from './Components/SocialPost'
import AddSocialPost from './Components/AddSocialPost'
import IqCard from '../../../components/socialvue/cards/iq-card'
import Map from '../../../views/Intimlite/Components/Map'
import api from "@/api/api"


export default {
  name: 'SocialApp',
  components: {IqCard, AddSocialPost, SocialPost, Map, api },
  mounted() {
    socialvue.index(),

    api.post("/client/search", {}).then((response) => {

      this.clients = response.data.clients
    }),

    api.post("client/getGenericData", {}).then((response) => {
      this.genericData = response.data
    })
  },
  data() {
    return {
      genericData: [],
      clients: null,
      min_breast_size: null,
      max_breast_size: null,
      min_age: null,
      max_age: null,
      checkedServices: [],
      checkedOrientations: [],
      suggestionEvent: [
        {
          img: require('../../../assets/images/page-img/42.png'),
          title: 'Iqonic Studio',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-1.jpg')
        },
        {
          img: require('../../../assets/images/page-img/43.png'),
          title: 'Cakes & Bakes ',
          des: 'Lorem Ipsum',
          otherImg: require('../../../assets/images/small/img-2.jpg')
        }
      ],
      event: [
        {
          img: require('../../../assets/images/page-img/s4.jpg'),
          heading: 'Web Workshop',
          time: '1 hour ago'
        },
        {
          img: require('../../../assets/images/page-img/s5.jpg'),
          heading: 'Fun Events and Festivals',
          time: '4 hour ago'
        }
      ],
      user: {
        background: require('../../../assets/images/page-img/profile-bg.jpg'),
        profile: require('../../../assets/images/user/user-11.png'),
        name: 'Nik Jone',
        role: ' - Web designer',
        about: 'Lorem ipsum dolor sit amet, contur adipiscing elit.',
        email: 'nikjone@demoo.com',
        phone: '001 2351 256 12',
        location: 'USA',
        careated_date: '07 Jan 2020'
      },
      galary: [
        {image: require('../../../assets/images/page-img/g1.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g2.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g3.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g4.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g5.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g6.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g7.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g8.jpg'), href: 'javascript:void(0);'},
        {image: require('../../../assets/images/page-img/g9.jpg'), href: 'javascript:void(0);'}
      ],
      action: [
        {
          icon: 'ri-eye-fill mr-2',
          title: 'View'
        },
        {
          icon: 'ri-delete-bin-6-fill mr-2',
          title: 'Delete'
        },
        {
          icon: 'ri-pencil-fill mr-2',
          title: 'Edit'
        },
        {
          icon: 'ri-printer-fill mr-2',
          title: 'Print'
        },
        {
          icon: 'ri-file-download-fill mr-2',
          title: 'Download'
        }
      ],
      timelineItems: [
        {
          color: 'primary',
          title: 'Client Login',
          right: '24 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: []
          }
        },
        {
          color: 'success',
          title: 'Scheduled Maintenance',
          right: '23 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: []
          }
        },
        {
          color: 'danger',
          title: 'Dev Meetup',
          right: '20 November 2019',
          description: 'Bonbon macaroon jelly <b-link href="">beans gummi</b-link> bears jelly lollipop apple',
          child: {
            type: 'img',
            items: [
              require('../../../assets/images/user/user-05.jpg'),
              require('../../../assets/images/user/user-06.jpg'),
              require('../../../assets/images/user/user-07.jpg'),
              require('../../../assets/images/user/user-08.jpg'),
              require('../../../assets/images/user/user-09.jpg'),
              require('../../../assets/images/user/user10.jpg')
            ]
          }
        },
        {
          color: 'primary',
          title: 'Client Call',
          right: '19 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: []
          }
        },
        {
          color: 'warning',
          title: 'Mega Event',
          right: '15 November 2019',
          description: 'Bonbon macaroon jelly beans gummi bears jelly lollipop apple',
          child: {
            type: 'img',
            items: []
          }
        }
      ],
      friends: [
        {name: 'Paul Molive', role: 'Web Designer', image: require('../../../assets/images/user/user-01.jpg')},
        {name: 'Paul Molive', role: 'trainee', image: require('../../../assets/images/user/user-01.jpg')}
      ],
      userBio: [
        {title: 'Joined', description: 'November 15, 2012'},
        {title: 'Lives', description: 'United States of America'},
        {title: 'Email', description: '<a href="mailto:nikjone@gmail.com"> nikjone@gmail.com</a>'},
        {
          title: 'Url',
          description: '<a href="https://getbootstrap.com/docs/4.0/getting-started/introduction/" target="_blank"> www.bootstrap.com </a>'
        },
        {title: 'Contact', description: '<a href="tel:001 4544 565 456">(001) 4544 565 456</a>'}
      ],
      story: [
        {
          title: 'Web Design',
          time: '1 hour ago',
          image: require('../../../assets/images/page-img/s1.jpg'),
          class: 'mb-4',
          isActive: 'active'
        },
        {
          title: 'App Design',
          time: '4 hour ago',
          image: require('../../../assets/images/page-img/s2.jpg'),
          class: 'mb-4',
          isActive: ''
        },
        {
          title: 'Abstract Design',
          time: '9 hour ago',
          image: require('../../../assets/images/page-img/s3.jpg'),
          class: '',
          isActive: ''
        }
      ],
      suggestions: [
        {name: 'Paul Molive', mutual_friend: 'Today', image: require('../../../assets/images/user/user-01.jpg')},
        {name: 'Paul Molive', mutual_friend: 'Tomorrow', image: require('../../../assets/images/user/user-01.jpg')}
      ],
      news: [
        {description: 'there is a meetup in your city on friday at 19:00.<a href="#">see details</a>'},
        {description: '20% off coupon on selected items at pharmaprix'}
      ],
      twitterFeed: [
        {
          image: require('../../../assets/images/page-img/42.jpg'),
          name: 'UI/Ux Designer',
          description: 'Creativity Design'
        },
        {
          image: require('../../../assets/images/page-img/43.jpg'),
          name: 'Marketing',
          description: 'Accounting'
        },
        {
          image: require('../../../assets/images/page-img/44.jpg'),
          name: 'Web Developer',
          description: 'uniq Concept'
        },
        {
          image: require('../../../assets/images/page-img/45.jpg'),
          name: 'Latest News',
          description: 'Line information'
        }
      ],
      recentPages: [
        {
          image: require('../../../assets/images/page-img/46.jpg'),
          name: 'Food town'
        },
        {
          image: require('../../../assets/images/page-img/47.jpg'),
          name: 'Touro Univercity'
        },
        {
          image: require('../../../assets/images/page-img/48.jpg'),
          name: 'Moviehouse & Eatery'
        },
        {
          image: require('../../../assets/images/page-img/49.jpg'),
          name: 'Coffee + Crisp'
        }
      ]
    }
  },

  methods: {
    addPost(post) {
      this.socialPosts.unshift(post)
    },
    searchButton(post) {
      if (this.min_breast_size === "") this.min_breast_size = null;
      if (this.max_breast_size === "") this.max_breast_size = null;
      if (typeof this.min_age === "string") {
        if (this.min_age === "" ) this.min_age = null;
        else this.min_age = Number(this.min_age);
      }
      if (typeof this.max_age === "string") {
        if (this.max_age === "" ) this.max_age = null;
        else this.max_age = Number(this.max_age);
      } 
      api.post("/client/search", {
        name: this.name,
        breast_size: [this.min_breast_size,this.max_breast_size],
        age: [this.min_age,this.max_age],
        orientations: this.checkedOrientations,
        services: this.checkedServices,
        }).then((response) => {this.clients = response.data.clients});
    },
  }
}
</script>

<style>
  .card-gap {
    gap: 0px 15px;
  }

.land-page-nadpis {
    margin-left: 7px;
    font-size: 18px;
  }

.land-page-popis {
    margin-left: 7px;
    color: gray;
  }

.land-page-header-part {
  height: 130px;
}

.land-page-select {
  width: 250px;
  height: 30px;
  border-radius: 7px;
  outline: none;
}

.search_filter {
  width: 20px;
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.filtration_area {
  color: white;
  background-color: var(--iq-dark-sidebar);
  width: 95vw;
}

.filtration_part {
  width: 83vw;
  margin-top: 5px;
  margin-bottom: 5px;
  border-radius: 7px;

}

.filtration_submit {
  margin-top: 15px;
  background-color: #ef476f;
  height: 30px;
  text-align: center;
  padding-top: 2px;
}

[dir=ltr][mode=dark] h6.filtration_h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ef476f;
}

[dir=ltr][mode=dark] h4.filtration_h4 {
  margin-bottom: -20px;
}

.hr_filtration {
  margin-top: 0px;
}

.label_filtration {
  margin-left: 5px;
}
</style>
